import React from "react"
import * as S from "./styled"
import { ImageCaption, Title1 } from "../Texts"
import { Link } from "gatsby-plugin-modal-routing"
import { urlFor } from "../../util/url"
import SVG from "react-inlinesvg"
import ParallaxImage from "../ParallaxImage"
import Img from "gatsby-image/withIEPolyfill"

const BrandCard = ({
  height,
  objectFit = "cover",
  parallax = true,
  brand: {
    logo,
    // seo: { image },
    slug,
    name,
    images,
  },
  clickable,
  onBrandClick,
}) => {
  if (1 === 1)
    return (
      <S.BrandItem>
        <Link to={urlFor("DatoCmsBrand", slug)}>
          <S.MediaContainer>
            {(images.length > 0 || images[0]) && <Img {...images[0]} />}
          </S.MediaContainer>
          {name}
        </Link>
      </S.BrandItem>
    )
}

export default BrandCard
