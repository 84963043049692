import styled from "styled-components"

const SCALE_FACTOR = 1.04

export const ImageContainer = styled.div`
  cursor: ${props => props.clickable && "pointer"};

  .hover-wrapper {
    transition: all 250ms ease-out;
  }

  .gatsby-image-wrapper {
    transition: all 250ms ease-out;
    transform: scale(${SCALE_FACTOR});
  }
`

export const Item = styled.article`
  flex: 1;
  position: relative;
  transition: all 250ms ease-out;

  > :last-child {
    transform: translateY(50%);
  }

  :hover {
    ${ImageContainer} {
      .hover-wrapper {
        transform: scale(${SCALE_FACTOR});
      }
      .gatsby-image-wrapper {
        transform: scale(1);
      }
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: initial;
  transition: all 250ms ease-out;
  a {
    color: initial;
    text-decoration: none;
  }
`
