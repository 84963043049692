import React from "react"
import styled from "styled-components"
import device from "../../util/devices"

const Button = styled.button`
  border: 1px solid #171717;
  background: transparent;
  outline: none;
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  transition: all 150ms ease-out;

  &:hover {
    background: #171717;
    color: white;
  }

  @media (${device.phone}) {
    width: 100%;
  }
`

export default Button
